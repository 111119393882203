export const pendingPaymentsFiscalConstants = {
    //Crear
    PENDING_PAYMENTS_FISCAL_CREATE_REQUEST: 'PENDING_PAYMENTS_FISCAL_CREATE_REQUEST',
    PENDING_PAYMENTS_FISCAL_CREATE_SUCCESS: 'PENDING_PAYMENTS_FISCAL_CREATE_SUCCESS',
    PENDING_PAYMENTS_FISCAL_CREATE_FAILURE: 'PENDING_PAYMENTS_FISCAL_CREATE_FAILURE',

    PENDING_PAYMENTS_FISCAL_TABLE_REQUEST: 'PENDING_PAYMENTS_FISCAL_TABLE_REQUEST',
    PENDING_PAYMENTS_FISCAL_TABLE_SUCCESS: 'PENDING_PAYMENTS_FISCAL_TABLE_SUCCESS',
    PENDING_PAYMENTS_FISCAL_TABLE_FAILURE: 'PENDING_PAYMENTS_FISCAL_TABLE_FAILURE',

    PENDING_PAYMENTS_FISCAL_UPDATE_REQUEST: 'PENDING_PAYMENTS_FISCAL_UPDATE_REQUEST',
    PENDING_PAYMENTS_FISCAL_UPDATE_SUCCESS: 'PENDING_PAYMENTS_FISCAL_UPDATE_SUCCESS',
    PENDING_PAYMENTS_FISCAL_UPDATE_FAILURE: 'PENDING_PAYMENTS_FISCAL_UPDATE_FAILURE',
};
